<template>
	<Component
		:is="currentEditBlockSlideshowComponent"
		:start-tab-id="startTabId"
		@close="closeEditBlockSlideshowComponent"
	/>
</template>

<script>
// Current open component will be controlled via useEditBlockSlideshow composable with next PR
import { onMounted } from '@vue/composition-api';

import { useEditBlockSlideshow } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshow';

export default {
	components: {
		EditBlockSlideshowSettingsTabSlides: () => import('@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSettingsTabSlides.vue'),
		EditBlockSlideshowSettingsTabs: () => import('@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSettingsTabs.vue'),
		EditBlockSlideshowSlideBackground: () => import('@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSlideBackground.vue'),
	},
	props: {
		startingPopupComponent: {
			type: String,
			default: '',
		},
		startTabId: {
			type: String,
			default: 'styles',
		},
	},
	setup(props, context) {
		const {
			currentEditBlockSlideshowComponent,
			setEditBlockSlideshowComponent,
			closeEditBlockSlideshowComponent,
		} = useEditBlockSlideshow(props, context);

		onMounted(() => {
			setEditBlockSlideshowComponent(props.startingPopupComponent);
		});

		return {
			closeEditBlockSlideshowComponent,
			currentEditBlockSlideshowComponent,
		};
	},
};
</script>
